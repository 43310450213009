<template>
  <div class="reportPage">
    <h2 class="mt-5">{{ $t(config.crudHeader) }}</h2>
    <v-container fluid>
      <v-btn color="danger" hide-details small id="print" @click="print">{{
        $t("Print")
      }}</v-btn>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("CustomerName") }}</th>
            <th>{{ $t("PackageName") }}</th>
            <th>{{ $t("StartDate") }}</th>
            <th>{{ $t("EndDate") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in items" :key="index">
            <td>{{ row.id }}</td>
            <td>{{ row.customer_name }}</td>
            <td>{{ row.package_name }}</td>
            <td>{{ row.start_date }}</td>
            <td>{{ row.end_date }}</td>
          </tr>
        </tbody>
      </table>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      config: {
        crudHeader: "Subscribes",
        crudApi: "subscribe",
      },
    };
  },
  methods: {
    initData() {
      this.$store
        .dispatch(`${this.config.crudApi}/listAll`)
        .then((res) => {
          this.items = res.data;
          localStorage.removeItem("filterReport");
        });
    },
    print() {
      window.print();
    },
  },
  created() {
    let app_title = document.getElementById("app_title");
    app_title.innerText = this.$t(this.config.crudHeader);
    this.initData();
  },
};
</script>